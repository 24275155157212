import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import he from 'he'

class TextItem extends Component {
  render() {
    let link = this.props.slug ? this.props.slug : this.props.post_name
    let url = `/article/${link}`
    let feature_image = this.props.acf && this.props.acf.featured_image && this.props.acf.featured_image.url
    return (
      <Link to={ url } className='archive__text-item'>
        <Fade bottom distance='40px'>
          <picture style={{ backgroundImage: `url(${ feature_image })` }} />
          <div className='archive__text-item__text'>
            <span>{ this.props.category }</span>
            { he.decode(this.props.title || this.props.post_title) }
          </div>
        </Fade>
      </Link>
    )
  }
}

export default TextItem
